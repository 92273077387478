import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Hidden,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  withStyles
} from "@material-ui/core";
import moment from "moment";
import { withSnackbar } from "notistack";
import ListAltIcon from "@material-ui/icons/ListAlt";
// import FolderIcon from "@material-ui/icons/Folder";

import fileDownload from "js-file-download";
import ConfirmationDialog, { stopPropagationForConfirmation } from "../dialog/ConfirmationDialog";
import ContraliaService from "../../services/ContraliaService";
import EmbeddedContraliaDialog from "../dialog/EmbeddedContraliaDialog";
import OrderFormListFilter from "./OrderFormListFilter";
import OrderFormService from "../../services/OrderFormService";
import TableAction from "../TableAction";
import TableSortableHeader from "../table/TableSortableHeader";
import { formatCurrency } from "../../utils/numberFormat";
import { privateRoutes } from "../../routes";
import OrderFormStatusLabel from "./OrderFormStatusLabel";
import LinkedInvoicesDialog from "../dialog/LinkedInvoicesDialog";
import { ORDERFROM_STATUS } from "../../utils/status";
import PreviewOrderFormDialog from "../dialog/PreviewOrderFormDialog";
import FinancingService from "../../services/FinancingService";
import ContraliaConfirmationDialog from "../dialog/ContraliaConfirmationDialog";
import YouSignService from "../../services/YouSignService";
import OrderFormYouSignDialog from "../dialog/OrderFormYouSignDialog";
import { contactCanSign } from "../../utils/signature";
import LinkedAdditionalDocumentsDialog from "../dialog/LinkedAdditionalDocumentsDialog";
import FranfinanceTransferTypeDialog from "../financing/FranfinanceTransferTypeDialog";

const styles = theme => ({
  container: {
    padding: theme.spacing(2)
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(10),
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  },
  loadingCell: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  buttonsContainer: {
    flex: 2,
    justifyContent: "flex-end",
    display: "flex"
  },
  rightAlign: {
    textAlign: "right"
  },
  centerAlign: {
    textAlign: "center"
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "rgb(224,224,244)"
  }
});

const ROWS_PER_PAGE = 50;

const canBeCancel = orderForm => {
  let isCancellable = true;
  if (orderForm.invoices.length > 0) {
    orderForm.invoices.forEach(invoice => {
      if (invoice.status !== "CANCEL") {
        isCancellable = false;
      }
    });
  }
  return isCancellable;
};

const defaultFilter = {
  q: "",
  userId: "",
  status: [],
  from: null,
  to: null,
  client: ""
};

@inject("sessionStore")
@observer
class OrderFormList extends Component {
  constructor(props) {
    super(props);
    const { filter } = props;
    this.state = {
      UrlSignature: "",
      currentPage: 0,
      currentSort: null,
      filter: { ...defaultFilter, ...(filter || {}) },
      gonnaBeRefreshed: false,
      openLinkedInvoices: false,
      orderForms: null,
      selectedOrderForm: null,
      totalCount: 0,
      // CONTRALIA
      transactionId: "",
      download: "",
      downloadHref: "",
      openSign: false,
      openContraliaConfirmation: false,
      // YOUSIGN
      openYouSignDialog: false,
      //
      menuOpenFor: null,
      openPreview: false,
      openLinkedAdditionalDocumentsDialog: false,
      openFranfinanceContextTransferTypeDialog: false,
      isExporting: false,
      anchorEl: null
    };
  }

  componentDidMount() {
    this.fetchOrderForms();
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChangePage = (event, page) => {
    if (this._isMounted) {
      this.setState({ currentPage: page }, () => {
        this.fetchOrderForms();
      });
    }
  };

  handleClose = () => {
    this.setState({ openSign: false, openYouSignDialog: false, selectedOrderForm: null });
    this.fetchOrderForms();
  };

  handleCancel = orderForm => {
    const { enqueueSnackbar } = this.props;
    OrderFormService.cancelOrderForm(orderForm)
      .then(() => {
        this.fetchOrderForms();
        enqueueSnackbar("Le bon de commande a été annulé", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de l'annulation du bon", { variant: "error" });
      });
  };

  handleDelete = orderForm => {
    const { enqueueSnackbar } = this.props;
    OrderFormService.deleteOrderForm(orderForm)
      .then(() => {
        this.fetchOrderForms();
        enqueueSnackbar("Le bon de commande a été supprimé", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la suppression du bon", { variant: "error" });
      });
  };

  // Call on a draft order form to initialize the signature
  handleInitSignature = orderForm => {
    OrderFormService.signOrderForm(orderForm.id)
      .then(res => {
        if (res.signatureProvider === "CONTRALIA") {
          this.setState({
            transactionId: res.transactionId,
            UrlSignature: res.urlSignature,
            openSign: true,
            openContraliaConfirmation: false,
            selectedOrderForm: null
          });
        }
        if (res.signatureProvider === "YOUSIGN") {
          this.handleSignature(orderForm);
        }
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de l'initialisation de la signature", { variant: "error" });
      });
  };

  // Open existing signature
  handleSignature = orderForm => {
    OrderFormService.getOrderFormSignature(orderForm.id)
      .then(res => {
        if (this._isMounted) {
          if (res.signatureProvider === "CONTRALIA") {
            this.setState({ transactionId: orderForm.transactionId, UrlSignature: res.url, openSign: true });
          }
          if (res.signatureProvider === "YOUSIGN") {
            this.setState({
              selectedOrderForm: orderForm,
              openYouSignDialog: true
            });
          }
        }
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors du chargement de la signature", { variant: "error" });
      });
  };

  handleShowLinkedInvoices = orderForm => {
    this.setState({ openLinkedInvoices: true, selectedOrderForm: orderForm });
  };

  handleHideLinkedInvoices = () => {
    this.setState({ openLinkedInvoices: false, selectedOrderForm: null });
  };

  handleDeleteRawPDF = orderForm => {
    OrderFormService.downloadRawPDF(orderForm.id)
      .then(res => {
        const downloadHref = window.URL.createObjectURL(res);
        if (this._isMounted) {
          this.setState({ downloadHref, download: `${orderForm.number}.pdf` });
        }
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors du téléchargement du PDF du bon de commande", {
          variant: "error"
        });
      });
  };

  downloadPDF = orderForm => {
    if (
      !OrderFormService.signatureExpired(orderForm) ||
      orderForm.transactionCloseDate ||
      orderForm.signatureRequestDoneDate
    ) {
      if (orderForm.transactionId) {
        ContraliaService.getDownloadPDF(orderForm.transactionId)
          .then(res => {
            const downloadHref = window.URL.createObjectURL(res);
            if (this._isMounted) {
              this.setState({ downloadHref, download: `${orderForm.number}.pdf` });
            }
          })
          .catch(() => {
            const { enqueueSnackbar } = this.props;
            enqueueSnackbar("Une erreur est survenue lors du téléchargement du PDF du bon de commande", {
              variant: "error"
            });
          });
      } else if (orderForm.signatureRequestId) {
        YouSignService.getDownloadOrderPDF(orderForm.signatureRequestId)
          .then(res => {
            const downloadHref = window.URL.createObjectURL(res);
            if (this._isMounted) {
              this.setState({ downloadHref, download: `${orderForm.number}.pdf` });
            }
          })
          .catch(() => {
            const { enqueueSnackbar } = this.props;
            enqueueSnackbar("Une erreur est survenue lors du téléchargement du PDF du bon de commande", {
              variant: "error"
            });
          });
      } else {
        this.handleDeleteRawPDF(orderForm);
      }
    } else {
      this.handleDeleteRawPDF(orderForm);
    }
  };

  previewPDF = orderForm => {
    this.setState({
      openPreview: true,
      selectedOrderForm: orderForm
    });
  };

  handleHidePreview = () => {
    this.setState({
      openPreview: false,
      selectedOrderForm: null
    });
  };

  simulateClick = e => {
    // Avoid a blank page by checking if e is null or not.
    // Just remove the if condition to check the behavior.
    if (e !== null) {
      e.click();
    }
    this.setState({ downloadHref: "" });
  };

  sendSofincoContext = orderForm => {
    const { information, contact, number, amount } = orderForm;
    const parsedInformation = JSON.parse(information);
    FinancingService.sendSofincoContext({
      financingForm: parsedInformation.financingForm,
      contact,
      externalId: number,
      amount
    })
      .then(res => {
        window.open(res.url);
      })
      .catch(e => {
        const { enqueueSnackbar } = this.props;
        if (e && e.status === 412) {
          enqueueSnackbar("Une configuration de votre identifiant Sofinco est nécessaire", { variant: "error" });
        } else {
          enqueueSnackbar("Une erreur est survenue lors de la transmission des informations à Sofinco", {
            variant: "error"
          });
        }
      });
  };

  sendFranfinanceContext = (orderForm, transferType) => {
    FinancingService.sendFranfinanceContext(orderForm, "order", transferType)
      .then(res => {
        window.open(res.url);
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de la transmission des informations à Franfinance", {
          variant: "error"
        });
      });
  };

  handleShowFranfinanceContextTransferTypeDialog = orderForm => {
    this.setState({ openFranfinanceContextTransferTypeDialog: true, selectedOrderForm: orderForm });
  };

  handleHideFranfinanceTransferTypeDialog = () => {
    this.setState({ openFranfinanceContextTransferTypeDialog: false, selectedOrderForm: null });
  };

  handleSubmitFranfinanceTransferTypeDialog = transferType => {
    const { selectedOrderForm } = this.state;
    this.sendFranfinanceContext(selectedOrderForm, transferType);
    this.handleHideFranfinanceTransferTypeDialog();
  };

  addAdditionalDocument = (orderForm, type) =>
    OrderFormService.addAdditionalDocument(orderForm, type)
      .then(created => {
        const { enqueueSnackbar, history } = this.props;
        enqueueSnackbar("Le document a été créé", { variant: "success" });
        history.push(
          privateRoutes.common.additionalDocuments.worksAcceptanceDetails.path.replace(
            ":additionalDocumentId",
            created.id
          )
        );
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de la création du document", { variant: "error" });
      });

  patchOrderFormStatus = (orderForm, status) => {
    OrderFormService.patchOrderFormStatus(orderForm, status)
      .then(() => {
        this.fetchOrderForms();
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de la mise à jour du statut du bon de commande", {
          variant: "error"
        });
      });
  };

  renderMenu = orderForm => {
    const { sessionStore } = this.props;
    const isCancellable = canBeCancel(orderForm);
    const userCanAdditionalDocument = sessionStore.userHasAccess({
      requiredFeatures: ["ADDITIONAL_DOCUMENT"],
      requiredAcls: ["ADDITIONAL_DOCUMENT_MY", "ADDITIONAL_DOCUMENT_READ"],
      aclType: "oneof"
    });
    const userCanEndWorksAcceptance =
      sessionStore.userHasAccess({
        requiredFeatures: ["ADDITIONAL_DOCUMENT"],
        requiredAcls: ["ADDITIONAL_DOCUMENT_MY"]
      }) &&
      sessionStore.userHasAccess({
        requiredFeatures: ["OPTION_ADDITIONAL_DOCUMENT_WORKS_ACCEPTANCE"]
      });
    const userCanVatDeclaration =
      sessionStore.userHasAccess({
        requiredFeatures: ["ADDITIONAL_DOCUMENT"],
        requiredAcls: ["ADDITIONAL_DOCUMENT_MY"]
      }) &&
      sessionStore.userHasAccess({
        requiredFeatures: ["OPTION_ADDITIONAL_DOCUMENT_VAT_DECLARATION"]
      });

    if (
      orderForm.user.id === sessionStore.user.id ||
      sessionStore.userHasAccess({
        requiredAcls: ["ORDER_WRITE"],
        aclType: "oneof",
        requiredFeatures: ["ORDER"]
      })
    ) {
      const { information } = orderForm;
      const parsedInformation = JSON.parse(information);
      if (orderForm.status === "CANCEL") {
        return (
          <TableAction
            onOpen={() => this.setState({ menuOpenFor: orderForm.id })}
            onClose={() => this.setState({ menuOpenFor: null })}
          >
            {sessionStore.userHasAccess({
              requiredAcls: ["ORDER_MY"],
              requiredFeatures: ["ORDER"]
            }) && (
              <MenuItem
                key="menuitem-orderform"
                component={Link}
                to={{
                  pathname: privateRoutes.common.orderForms.creationForm.path,
                  state: { fromOrderForm: orderForm }
                }}
              >
                Dupliquer
              </MenuItem>
            )}
            <MenuItem onClick={() => this.previewPDF(orderForm)}>Prévisualiser</MenuItem>
            <ConfirmationDialog
              situationText="Êtes-vous sûr de vouloir supprimer ce bon de commande ?"
              title="Confirmation"
              confirmText="Oui"
            >
              {confirmBeforeAct => (
                <MenuItem
                  onClick={e => {
                    stopPropagationForConfirmation(
                      e,
                      confirmBeforeAct(() => this.handleDelete(orderForm))
                    );
                  }}
                >
                  Supprimer
                </MenuItem>
              )}
            </ConfirmationDialog>
          </TableAction>
        );
      }
      return (
        <TableAction
          disabled={orderForm.deleted}
          onOpen={() => this.setState({ menuOpenFor: orderForm.id })}
          onClose={() => this.setState({ menuOpenFor: null })}
        >
          <MenuItem onClick={() => this.previewPDF(orderForm)}>Prévisualiser</MenuItem>
          <MenuItem onClick={() => this.downloadPDF(orderForm)}>Télécharger</MenuItem>
          {orderForm.paymentCondition === "FINANCING" &&
            parsedInformation.financingForm.financingType === "SOFINCO" &&
            orderForm.status === "SIGNED" && (
              <MenuItem onClick={() => this.sendSofincoContext(orderForm)}>Accéder à Sofinco</MenuItem>
            )}
          {orderForm.paymentCondition === "FINANCING" &&
            parsedInformation.financingForm.financingType === "FRANFINANCE" &&
            orderForm.status === "SIGNED" && (
              <MenuItem onClick={() => this.handleShowFranfinanceContextTransferTypeDialog(orderForm)}>
                Accéder à Franfinance
              </MenuItem>
            )}
          {(orderForm.transactionId || orderForm.signatureRequestId) &&
            orderForm.status === "DRAFT" &&
            !OrderFormService.signatureExpired(orderForm) &&
            sessionStore.userHasAccess({
              requiredAcls: ["SIGN_WRITE"],
              requiredFeatures: ["SIGN"]
            }) && <MenuItem onClick={() => this.handleSignature(orderForm)}>Signer</MenuItem>}
          {/* SIGN w/ INIT */}
          {!orderForm.transactionId &&
            !orderForm.signatureRequestId &&
            orderForm.status === "DRAFT" &&
            sessionStore.userHasAccess({
              requiredAcls: ["SIGN_WRITE"],
              requiredFeatures: ["SIGN"]
            }) && (
              <MenuItem
                disabled={orderForm.contact && !contactCanSign(orderForm.contact)}
                onClick={() => {
                  if (sessionStore.user.company.signatureProvider !== "YOUSIGN") {
                    this.setState({ menuOpenFor: null, openContraliaConfirmation: true, selectedOrderForm: orderForm });
                  } else {
                    this.setState({ selectedOrderForm: orderForm, openYouSignDialog: true });
                  }
                }}
              >
                Signer
              </MenuItem>
            )}
          {/*  */}
          {orderForm.status === "DRAFT" && [
            <ConfirmationDialog
              situationText={`Êtes-vous sûr de vouloir marquer le bon de commande ${orderForm.number} comme signé ?`}
              title="Confirmation"
              confirmText="Oui"
              key="menuitem-draft-action-confirmation-i"
            >
              {confirmBeforeAct => (
                <MenuItem
                  onClick={e => {
                    stopPropagationForConfirmation(
                      e,
                      confirmBeforeAct(() => this.patchOrderFormStatus(orderForm.id, "SIGNED"))
                    );
                  }}
                >
                  Marquer comme signé
                </MenuItem>
              )}
            </ConfirmationDialog>,
            <Divider key="menuitem-draft-action-divider-ii" />
          ]}
          {/*  */}
          {orderForm.status === "INVOICED" && orderForm.invoices.length > 0 && (
            <MenuItem onClick={() => this.handleShowLinkedInvoices(orderForm)}>
              Voir {orderForm.invoices.length > 1 ? "les factures" : "la facture"}
            </MenuItem>
          )}
          {sessionStore.userHasAccess({
            requiredAcls: ["ESTIMATE_MY"],
            requiredFeatures: ["ESTIMATE"]
          }) && (
            <MenuItem
              key="menuitem-as-order"
              component={Link}
              to={{
                pathname: privateRoutes.common.estimates.creationForm.path,
                state: { fromEstimate: { ...orderForm, limitDate: null } }
              }}
            >
              Transformer en devis
            </MenuItem>
          )}
          {orderForm.status !== "CANCEL" &&
            sessionStore.userHasAccess({
              requiredAcls: ["INVOICE_MY"],
              requiredFeatures: ["INVOICE"]
            }) && <MenuItem onClick={() => this.convertIntoInvoice(orderForm)}>Transformer en facture</MenuItem>}
          {orderForm.status !== "DRAFT" && [
            <Divider key="additional-documents-divider" />,
            userCanAdditionalDocument && orderForm.additionalDocuments && orderForm.additionalDocuments.length > 0 ? (
              <MenuItem
                key="additional-documents-list"
                onClick={() => this.handleShowLinkedAdditionalDocumentsDialog(orderForm)}
              >
                Documents liés
              </MenuItem>
            ) : null,
            userCanEndWorksAcceptance ? (
              <MenuItem
                key="ad-works-acceptance"
                onClick={() => this.addAdditionalDocument(orderForm, "WORKS_ACCEPTANCE")}
              >
                Créer un PV de fin de travaux
              </MenuItem>
            ) : null,
            userCanVatDeclaration ? (
              <MenuItem
                key="ad-vat-declaration"
                onClick={() => this.addAdditionalDocument(orderForm, "VAT_DECLARATION")}
              >
                Créer une attestation de TVA
              </MenuItem>
            ) : null,
            <Divider key="additional-documents-divider-after" />
          ]}
          {sessionStore.userHasAccess({
            requiredAcls: ["ORDER_MY"],
            requiredFeatures: ["ORDER"]
          }) && (
            <MenuItem
              key="menuitem-orderform"
              component={Link}
              to={{
                pathname: privateRoutes.common.orderForms.creationForm.path,
                state: { fromOrderForm: orderForm }
              }}
            >
              Dupliquer
            </MenuItem>
          )}
          {/*
          sessionStore.userHasAccess({
              requiredAcls: ["ORDER_CANCEL"],
              requiredFeatures: ["ORDER"]
            })
           */}
          {!orderForm.deleted && (
            <ConfirmationDialog
              situationText={
                isCancellable
                  ? "Êtes-vous sûr de vouloir annuler ce bon de commande ?"
                  : "Pour annuler ce bon de commande vous devez avant tout avoir annulé les factures associées."
              }
              title={isCancellable ? "Confirmation" : "Information"}
              confirmText={isCancellable ? "Oui" : "OK"}
            >
              {confirmBeforeAct => (
                <MenuItem
                  onClick={e => {
                    stopPropagationForConfirmation(
                      e,
                      confirmBeforeAct(() => (isCancellable ? this.handleCancel(orderForm) : true))
                    );
                  }}
                >
                  Annuler
                </MenuItem>
              )}
            </ConfirmationDialog>
          )}
        </TableAction>
      );
    }
    return null;
  };

  convertIntoInvoice = orderForm => {
    OrderFormService.intoInvoice(orderForm)
      .then(res => {
        const { history } = this.props;
        history.push(privateRoutes.admin.invoices.editionForm.path.replace(":invoiceId", res.success.id));
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de la transformation en facture du bon de commande", {
          variant: "error"
        });
      });
  };

  handleSort = arg => {
    this.setState({ currentSort: arg }, () => {
      this.fetchOrderForms();
    });
  };

  updateFilter = filter => {
    this.setState({ filter });
  };

  handleHideLinkedAdditionalDocumentsDialog = () => {
    this.setState({ openLinkedAdditionalDocumentsDialog: false, selectedOrderForm: null });
  };

  handleShowLinkedAdditionalDocumentsDialog = orderForm => {
    this.setState({ openLinkedAdditionalDocumentsDialog: true, selectedOrderForm: orderForm });
  };

  fetchOrderForms = () => {
    this.setState({ gonnaBeRefreshed: true }, () => {
      const { currentSort, filter, currentPage } = this.state;
      const { sessionStore } = this.props;
      const ziFilter = {
        ...filter,
        userId: !sessionStore.userHasAccess({
          requiredAcls: ["ORDER_READ"],
          requiredFeatures: ["ORDER"]
        })
          ? sessionStore.user.id
          : filter.userId || "",
        status:
          filter.status.length ===
          Object.keys(ORDERFROM_STATUS).filter(statusKey => ORDERFROM_STATUS[statusKey].hideForFilter !== true).length
            ? ""
            : filter.status.join(","),
        from: filter.from ? filter.from.format("YYYY-MM-DD") : "",
        to: filter.to ? filter.to.format("YYYY-MM-DD") : ""
      };
      OrderFormService.getOrderForms({
        page: currentPage,
        sort: currentSort ? currentSort.column : undefined,
        order: currentSort ? currentSort.order : undefined,
        filter: ziFilter
      })
        .then(response => {
          if (this._isMounted) {
            this.setState({ orderForms: response.content, totalCount: response.totalCount, gonnaBeRefreshed: false });
          }
        })
        .catch(() => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar("Une erreur est survenue lors de la récupération des bons de commande", { variant: "error" });
          if (this._isMounted) {
            this.setState({ gonnaBeRefreshed: false });
          }
        });
    });
  };

  handleCloseYouSignDialog = ({ needRefresh = false } = {}) => {
    this.setState({ openYouSignDialog: false, selectedOrderForm: null });
    if (needRefresh) {
      this.fetchOrderForms();
    }
  };

  handleOpenExportMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseExportMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleExportAsCsv = () => {
    const { filter } = this.state;
    const ziFilter = {
      ...filter,
      status: filter.status.length === Object.keys(ORDERFROM_STATUS).length ? "" : filter.status.join(","),
      from: filter.from ? filter.from.format("YYYY-MM-DD") : "",
      to: filter.to ? filter.to.format("YYYY-MM-DD") : ""
    };
    OrderFormService.exportOrderFormsAsCsv({ filter: ziFilter })
      .then(response => {
        fileDownload(response, "export.csv");
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de l'export des bons de commande", { variant: "error" });
      })
      .finally(() => {
        this.setState({ isExporting: false, anchorEl: null });
      });
  };

  render() {
    const { classes, title = "Bons de commande", sessionStore, disableExport = false } = this.props;
    const {
      UrlSignature,
      currentPage,
      currentSort,
      download,
      downloadHref,
      filter,
      gonnaBeRefreshed,
      openLinkedInvoices,
      openSign,
      orderForms,
      selectedOrderForm,
      totalCount,
      transactionId,
      menuOpenFor,
      openPreview,
      openContraliaConfirmation,
      openYouSignDialog,
      openLinkedAdditionalDocumentsDialog,
      openFranfinanceContextTransferTypeDialog,
      // signatureRequestId,
      // signers,
      isExporting,
      anchorEl
    } = this.state;
    const { CGV, withdrawal } = sessionStore.user.company;
    const isAccountValid =
      sessionStore.siggnedIn && CGV !== null && CGV !== undefined && withdrawal !== null && withdrawal !== undefined;

    const canExport = sessionStore.userHasAccess({
      requiredAcls: ["ORDER_EXPORT"],
      requiredFeatures: ["ORDER"]
    });

    return (
      <Fragment>
        <Grid container className={classes.container}>
          <Grid item xs={12} style={{ display: "flex", marginBottom: 24 }}>
            <Typography variant="h4">{title}</Typography>
            {sessionStore.userHasAccess({
              requiredAcls: ["ORDER_MY"],
              aclType: "oneof",
              requiredFeatures: ["ORDER"]
            }) && (
              <div className={classes.buttonsContainer}>
                <Hidden xsDown>
                  <Button
                    component={Link}
                    to={privateRoutes.common.orderForms.creationForm.path}
                    variant="outlined"
                    color="primary"
                    disabled={!isAccountValid}
                  >
                    Nouveau bon
                  </Button>
                </Hidden>
              </div>
            )}
          </Grid>
          <OrderFormListFilter
            filter={filter}
            defaultFilter={defaultFilter}
            updateFilter={this.updateFilter}
            onSubmit={this.fetchOrderForms}
          />
          {!disableExport && canExport && orderForms && orderForms.length > 0 && (
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                aria-controls="simple-export-menu"
                aria-haspopup="true"
                disabled={isExporting}
                variant="outlined"
                color="primary"
                style={{ marginTop: 24 }}
                onClick={this.handleOpenExportMenu}
              >
                {isExporting && <CircularProgress style={{ marginRight: 8 }} size={20} />} Exporter la liste
              </Button>
              <Menu
                id="simple-export-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={this.handleCloseExportMenu}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <ListItem button onClick={this.handleExportAsCsv}>
                  <ListItemIcon>
                    <ListAltIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Format CSV"
                    secondary="Toutes les données principales des bons de commande regroupées dans un tableau"
                  />
                </ListItem>
              </Menu>
            </Grid>
          )}
          <Grid item xs={12}>
            <Paper className={classes.root}>
              {orderForms && orderForms.length >= 15 && (
                <TablePagination
                  component="div"
                  count={totalCount}
                  rowsPerPage={ROWS_PER_PAGE}
                  rowsPerPageOptions={[ROWS_PER_PAGE]}
                  page={currentPage}
                  backIconButtonProps={{
                    "aria-label": "Page précédente"
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Page suivante"
                  }}
                  onChangePage={this.handleChangePage}
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
                  className={classes.borderBottom}
                />
              )}
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortableHeader
                        onClick={this.handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="orderForm.createAt"
                      >
                        Créé le
                      </TableSortableHeader>
                    </TableCell>
                    <TableCell style={{ width: "20%" }}>
                      <TableSortableHeader
                        onClick={this.handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="orderForm.number"
                      >
                        Numéro
                      </TableSortableHeader>{" "}
                      /{" "}
                      <TableSortableHeader
                        onClick={this.handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="orderForm.nature"
                      >
                        Objet
                      </TableSortableHeader>{" "}
                      /{" "}
                      <TableSortableHeader
                        onClick={this.handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="user.lastname"
                      >
                        Utilisateur
                      </TableSortableHeader>
                    </TableCell>
                    <TableCell>
                      <TableSortableHeader
                        onClick={this.handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="contact.lastname"
                      >
                        Client
                      </TableSortableHeader>
                    </TableCell>
                    <TableCell className={classes.centerAlign}>
                      <TableSortableHeader
                        onClick={this.handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="orderForm.totalPriceWithoutTVA"
                      >
                        Montant HT
                      </TableSortableHeader>
                    </TableCell>
                    <TableCell className={classes.centerAlign}>Montant TVA</TableCell>
                    <TableCell className={classes.centerAlign}>
                      <TableSortableHeader
                        onClick={this.handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="orderForm.totalPriceWithTVA"
                      >
                        Montant TTC
                      </TableSortableHeader>
                    </TableCell>
                    <TableCell>Statut</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderForms && orderForms.length >= 0 && gonnaBeRefreshed === false ? (
                    orderForms.map(orderForm => (
                      <TableRow key={orderForm.id} selected={orderForm.id === menuOpenFor}>
                        <TableCell>{moment(orderForm.createAt).format("DD/MM/YYYY")}</TableCell>
                        <TableCell style={{ width: "20%" }}>
                          N°{orderForm.number}
                          <br />
                          <span style={{ fontWeight: "bold" }}>{orderForm.nature}</span>
                          <br />
                          {orderForm.user && `par ${orderForm.user.firstname} ${orderForm.user.lastname.toUpperCase()}`}
                          {orderForm.additionalDocuments && orderForm.additionalDocuments.length > 0 && (
                            <Fragment>
                              <br />
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{ textDecoration: "underline", cursor: "pointer" }}
                                onClick={() => {
                                  this.handleShowLinkedAdditionalDocumentsDialog(orderForm);
                                }}
                              >
                                + {orderForm.additionalDocuments.length} document
                                {orderForm.additionalDocuments.length > 1 && "s"} lié
                                {orderForm.additionalDocuments.length > 1 && "s"}
                              </Typography>
                            </Fragment>
                          )}
                        </TableCell>
                        {/* {orderForm.contact ? (
                          <TableCell>
                            {orderForm.contact.businessName ? (
                              <Fragment>
                                {orderForm.contact.businessName}
                                <br />({orderForm.contact.firstname} {orderForm.contact.lastname.toUpperCase()})
                              </Fragment>
                            ) : (
                              <Fragment>
                                {orderForm.contact.firstname} {orderForm.contact.lastname.toUpperCase()}
                              </Fragment>
                            )}
                          </TableCell>
                        ) : ( */}
                        <TableCell>
                          {orderForm.clientBusinessName}
                          {orderForm.clientBusinessName && <br />}
                          {`${orderForm.clientBusinessName && "("}${
                            orderForm.clientFirstname
                          } ${orderForm.clientLastname.toUpperCase()}${orderForm.clientBusinessName && ")"}`}
                        </TableCell>
                        {/* )} */}
                        <TableCell className={classes.rightAlign}>
                          {formatCurrency(orderForm.totalPriceWithoutTVA, orderForm.currency)}
                        </TableCell>
                        <TableCell className={classes.rightAlign}>
                          {formatCurrency(
                            orderForm.totalPriceWithTVA - orderForm.totalPriceWithoutTVA,
                            orderForm.currency
                          )}
                        </TableCell>
                        <TableCell className={classes.rightAlign}>
                          {formatCurrency(orderForm.totalPriceWithTVA, orderForm.currency)}
                        </TableCell>
                        <TableCell>
                          <OrderFormStatusLabel orderForm={orderForm} />
                        </TableCell>
                        <TableCell>{this.renderMenu(orderForm)}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} align="center" className={classes.loadingCell}>
                        <CircularProgress size={40} />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {orderForms && orderForms.length >= 0 && (
                <TablePagination
                  component="div"
                  count={totalCount}
                  rowsPerPage={ROWS_PER_PAGE}
                  rowsPerPageOptions={[ROWS_PER_PAGE]}
                  page={currentPage}
                  backIconButtonProps={{
                    "aria-label": "Page précédente"
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Page suivante"
                  }}
                  onChangePage={this.handleChangePage}
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
                />
              )}
            </Paper>
          </Grid>
          {downloadHref !== "" && (
            <a ref={this.simulateClick} href={downloadHref} download={download} style={{ display: "none" }}>
              Download
            </a>
          )}
        </Grid>

        <EmbeddedContraliaDialog
          open={openSign}
          UrlSignature={UrlSignature}
          transactionId={transactionId}
          handleClose={this.handleClose}
        />

        {openYouSignDialog && (
          <OrderFormYouSignDialog
            currentUser={sessionStore.user}
            open={openYouSignDialog}
            doc={selectedOrderForm}
            handleClose={this.handleCloseYouSignDialog}
          />
        )}

        <LinkedInvoicesDialog
          onClose={this.handleHideLinkedInvoices}
          open={openLinkedInvoices}
          orderForm={selectedOrderForm}
        />

        <ContraliaConfirmationDialog
          open={openContraliaConfirmation}
          contact={selectedOrderForm ? selectedOrderForm.contact : undefined}
          onCancel={() => {
            this.setState({ openContraliaConfirmation: false });
          }}
          onConfirm={() => {
            this.handleInitSignature(selectedOrderForm);
          }}
        />

        <PreviewOrderFormDialog onClose={this.handleHidePreview} open={openPreview} orderForm={selectedOrderForm} />
        <LinkedAdditionalDocumentsDialog
          onClose={this.handleHideLinkedAdditionalDocumentsDialog}
          open={openLinkedAdditionalDocumentsDialog}
          orderForm={selectedOrderForm}
        />
        <FranfinanceTransferTypeDialog
          onClose={this.handleHideFranfinanceTransferTypeDialog}
          open={!!selectedOrderForm && openFranfinanceContextTransferTypeDialog}
          onSubmit={this.handleSubmitFranfinanceTransferTypeDialog}
        />
      </Fragment>
    );
  }
}

export default withSnackbar(withRouter(withStyles(styles)(OrderFormList)));
